import Empty from "@/components/Empty";
import NavBar from "@/components/NavBar";
import { TokenStatusEnum } from "@/enums";
import globalModel from "@/models/global.model";
import marketModel from "@/models/market.model";
import { useInfiniteScroll, useReactive, useRequest } from "ahooks";
import Decimal from "decimal.js";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Cell,
  Field,
  Form,
  Image,
  List,
  Popup,
  PullRefresh,
  Tag,
  Toast,
} from "react-vant";
import { useSnapshot } from "valtio";
import styles from "./index.module.css";
import Loader from "@/components/Loader";
import assetModel from "@/models/asset.model";

const ListView = ({
  id,
  type,
  sort,
  onClick,
  onSuccess,
}: {
  id: number;
  type: string;
  sort?: string;
  onClick: (item: any) => Promise<void>;
  onSuccess: (data: any) => Promise<void>;
}) => {
  const { data, loadMoreAsync, reloadAsync, noMore } = useInfiniteScroll(
    (d) => {
      return marketModel
        .sales({
          id,
          sort: {
            [type]: sort + "end",
          },
          params: {
            current: d ? d?.current + 1 : 1,
            pageSize: 20,
          },
        })
        .then((res) => res?.data);
    },
    {
      reloadDeps: [id, type, sort],
      isNoMore: (d) => (d ? !d?.hasMore : true),
      onSuccess,
    }
  );
  return (
    <PullRefresh className={styles.list} onRefresh={reloadAsync}>
      {!data || data?.list?.length <= 0 ? (
        <Empty />
      ) : (
        <List
          finished={noMore}
          finishedText="没有更多数据了～"
          onLoad={async () => {
            await loadMoreAsync();
          }}
        >
          {data?.list?.map((item: any) => (
            <div
              id={item.id}
              className={styles.item}
              onClick={() => onClick(item)}
            >
              <div className={styles.left}>
                <div className={styles.title}>
                  {TokenStatusEnum.ON_SALE === item.status ? (
                    <Tag className={styles.status} type="primary">
                      寄售
                    </Tag>
                  ) : (
                    <Tag className={`${styles.status} ${styles.locking}`}>
                      锁定中
                    </Tag>
                  )}
                  {item.title}
                  <div className={styles.payments}>
                    <div className={styles.payment}>易</div>
                  </div>
                </div>
                <div className={styles.no}>
                  #{item.no}
                  <span>/{item.limit}</span>
                </div>
              </div>
              <div className={styles.right}>
                <div className={styles.price}>
                  ￥<span>{new Decimal(item.price).toFixed(2)}</span>
                </div>
                <Image
                  className={styles.go}
                  src={require("@/assets/icons/go.png").default}
                />
              </div>
            </div>
          ))}
        </List>
      )}
    </PullRefresh>
  );
};

export default () => {
  const state = useReactive({
    total: 0,
    type: "price",
    sort1: "asc",
    sort2: "asc",
    scrollTop: 0,
    visible: false,
    passwordVisible: false,
  });
  const nav = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const { user } = useSnapshot(globalModel.state);
  const { data } = useRequest(
    () => {
      return assetModel.detail(Number(params.id)).then((res: any) => res.data);
    },
    {
      ready: !!params.id,
      refreshDeps: [params.id],
    }
  );
  return (
    <div className={styles.page}>
      <NavBar
        title={`寄售列表(${state.total}份)`}
        onScroll={(e) => (state.scrollTop = e)}
      />
      {!data ? (
        <Loader />
      ) : (
        <>
          <div className={styles.container}>
            <ListView
              id={data.id}
              type={state.type}
              sort={state.type === "price" ? state.sort1 : state.sort2}
              onSuccess={async (data) => {
                state.total = data?.total ?? 0;
              }}
              onClick={async (item) => {
                nav(`/asset/detail/${item.id}`);
              }}
            />
          </div>

          <div className={styles.footer}>
            <Button
              type="primary"
              round
              block
              onClick={async () => {
                const update = Toast({
                  type: "loading",
                  message: "正在提交...",
                  forbidClick: true,
                  duration: 0,
                });
                const res = await marketModel.order({
                  id: data.id,
                  num: 1,
                  price: data.highestPrice,
                });
                if (res?.code === 200) {
                  update.config({
                    type: "success",
                    message: "下单成功",
                    forbidClick: true,
                  });
                  if (!!res.data?.no) {
                    nav(`/order/detail/${res.data?.no}`);
                  }
                }
                setTimeout(() => {
                  update.clear();
                }, 2000);
              }}
            >
              快捷下单
            </Button>
            {!!user?.isBatch && (
              <Button
                type="info"
                round
                block
                onClick={() => {
                  form.setFieldsValue({
                    num: 10,
                    price: data.highestPrice,
                  });
                  state.visible = true;
                }}
              >
                批量下单
              </Button>
            )}
          </div>

          <Popup
            position="bottom"
            round
            title="批量下单"
            closeable
            closeOnClickOverlay
            visible={state.visible}
            onClose={() => (state.visible = false)}
          >
            <div className={styles.popup}>
              <Form
                className={styles.form}
                form={form}
                onFinish={async (values: any) => {
                  state.visible = false;
                  const update = Toast({
                    type: "loading",
                    message: "正在提交...",
                    forbidClick: true,
                    duration: 0,
                  });
                  const res = await marketModel.order({
                    id: data.id,
                    ...values,
                  });
                  if (res?.code === 200) {
                    update.config({
                      type: "success",
                      message: "下单成功",
                      forbidClick: true,
                    });
                    if (!!res.data?.no) {
                      nav(`/order/detail/${res.data?.no}`);
                    }
                  }
                  setTimeout(() => {
                    update.clear();
                  }, 2000);
                }}
                footer={
                  <Button
                    className={styles.submit}
                    type="primary"
                    size="large"
                    block
                    onClick={async () => {
                      try {
                        await form.validateFields();
                        await form.submit();
                      } catch (e: any) {
                        if (e && e.errorFields) {
                          Toast({
                            message: e.errorFields[0]?.errors[0],
                          });
                        }
                      }
                    }}
                  >
                    立即下单
                  </Button>
                }
              >
                <Cell
                  title="限价"
                  extra={
                    <div className={styles.price}>
                      <span>最高</span>
                      <Form.Item
                        name="price"
                        noStyle
                        rules={[
                          {
                            validator: async (_, value) => {
                              try {
                                if (!value) {
                                  throw new Error("请输入限价");
                                }
                                if (value < data?.lowestPrice) {
                                  throw new Error(
                                    `限价不能小于${data?.lowestPrice}元`
                                  );
                                }
                                if (value > data?.highestPrice) {
                                  throw new Error(
                                    `限价不能大于${data?.highestPrice}元`
                                  );
                                }
                              } catch (err: any) {
                                return Promise.reject(err);
                              }
                            },
                          },
                        ]}
                      >
                        <Field className={styles.input} type="digit" />
                      </Form.Item>
                      <span>元</span>
                    </div>
                  }
                />
                <Cell
                  title="数量"
                  extra={
                    <div className={styles.price}>
                      <span>购买</span>
                      <Form.Item
                        name="num"
                        noStyle
                        rules={[
                          {
                            validator: async (_, value) => {
                              try {
                                if (!value) {
                                  throw new Error("请输入数量");
                                }
                                if (value < 1) {
                                  throw new Error(`数量不能小于1`);
                                }
                                if (value > 10) {
                                  throw new Error(`数量不能大于10`);
                                }
                              } catch (err: any) {
                                return Promise.reject(err);
                              }
                            },
                          },
                        ]}
                      >
                        <Field className={styles.input} type="digit" />
                      </Form.Item>
                      <span>个</span>
                    </div>
                  }
                />
              </Form>
            </div>
          </Popup>
        </>
      )}
    </div>
  );
};
